import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { actions } from './__redux__'
import Select from '../../component/select'
import { listDisputeCustomers as fetchList } from '../../domain/account/customer'
const empty_value = { value: '', label: '' }

export default (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const list = useSelector((state) => get(state, `${props.name}.list`, []))
  const selected = useSelector((state) =>
    get(state, `${props.name}.selected`, { ...empty_value })
  )

  //Estado que controla o que está sendo mostrado no input de cada select
  const [inputValue, setInputValue] = useState('')
  const [listValue, setListValue] = useState([])

  //Handler da alteração no valor do input
  const handleInputChange = (value) => {
    setInputValue(value)
  }

  const useUpdateEffect = (effect, deps) => {
    const isFirstMount = useRef(true)

    useEffect(() => {
      if (!isFirstMount.current) effect()
      else isFirstMount.current = false
    }, deps)
  }
  //Handler da seleção manual de um item da lista
  const handleChangeSelect = (selected, name) => {
    selected && selected.value !== '' && selected !== null
      ? select(selected)
      : select(null)
  }

  //Handler centralizado da seleção de itens. Toda atualização do item selecionado deve
  //acontecer através desse método
  const select = (selected, onSelect = true, onDispatch = true) => {
    const dispatchData =
      selected && selected.value ? selected : { ...empty_value }
    const selectedData = selected && selected.value ? selected : null
    if (onSelect) props.onSelect({ name: props.name, selected: selectedData })
    if (onDispatch) dispatch(actions[props.name].setSelected(dispatchData))
  }

  //Quando uma nova lista é carregada, se a lista contiver apenas um item,
  //seta o item como sendo o selecionado.
  useUpdateEffect(() => {
    if (list.length === 1 && list[0]) {
      console.log(list[0][props.labelFromItem], list[0][props.valueFromItem])

      select({
        label:
          props.name === 'customerDispute'
            ? list[0][props.showAttributesAsLabel[0]] +
              ' - ' +
              list[0][props.showAttributesAsLabel[1]]
            : list[0][props.valueFromItem],
        value: list[0][props.valueFromItem]
      })
    }
  }, [list])

  useLayoutEffect(() => {
    setListValue(list)
  }, [list])

  //Quando o valor selecionado é alterado no redux (actions[props.name].setSelected),
  //setamos o valor do input para sempre estar sincronizado com o redux
  useLayoutEffect(() => {
    setInputValue(`${selected.label}`)
  }, [selected])

  const fetchNotFoundHandler = (query) => {
    fetchList(query).then((customers) => {
      if (customers.data.body.rows.length) {
        let list = customers.data.body.rows
        setListValue(list)
        if (customers.data.body.rows.length === 1)
          select({
            label:
              props.name === 'customerDispute'
                ? list[0][props.showAttributesAsLabel[0]] +
                  ' - ' +
                  list[0][props.showAttributesAsLabel[1]]
                : list[0][props.labelFromItem],
            value: list[0][props.valueFromItem]
          })
      }
    })
  }

  return (
    <Select
      handleInputChange={handleInputChange}
      onChangeValue={handleChangeSelect}
      inputSearchValue={inputValue}
      name={props.name}
      suggestions={listValue}
      label={t(props.translationLabel)}
      placeholder={t(props.translationPlaceholder)}
      useAttributesAsValue={props.useAttributesAsValue}
      showAttributesAsLabel={props.showAttributesAsLabel}
      notifySuggestionNotFound={(notification) => {
        if (props.fetchNotFound) {
          fetchNotFoundHandler(notification)
        }
      }}
    />
  )
}
