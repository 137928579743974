import React, { Fragment, useEffect, useState } from 'react'
import {
  Grid,
  Divider,
  Typography,
  Button,
  IconButton
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import FileList from '../../pages/customer-support/file-list'
import { createDispute } from '../../domain/dispute'
import Swal from 'sweetalert2'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import {
  applySelectFilter,
  changeLoading
} from '../document-filter/__redux__/actions'
import { fetchCompanies } from '../company-documents/__redux__/actions'
import Loading from '../../component/loading'

const getAuth = (state) => get(state, 'auth.user')
const getFilter = (state) => get(state, 'invoicesFilters')

export default ({
  filters,
  values,
  isReviewDialogOpen,
  setIsReviewDialogOpen,
  clearValues,
  setDisputeRequestDialogOpen,
  setActiveStep
}) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [disputeCasesPaginated, setDisputeCasesPaginated] = useState([])
  const offset = 5
  const totalPages = Math.ceil(values.length / offset)
  const user = useSelector(getAuth)
  const invoicesFilters = useSelector(getFilter)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const customerSelected = filters.filter(
    (filter) => filter.name === 'customer'
  )

  const handleNext = async () => {
    if (page >= totalPages) {
      const formDataValues = new FormData()
      for await (let data of values) {
        formDataValues.append('numbers', data.number)
        formDataValues.append(`description-${data.number}`, data.description)
        formDataValues.append(`code-${data.number}`, data.customerCode)
        formDataValues.append(`documentId-${data.number}`, data.id)
        formDataValues.append(`number-${data.number}`, data.number)
        formDataValues.append(`amount-${data.number}`, data.totalAmount)
        formDataValues.append(`issuedDate-${data.number}`, data.issuedDate)
        formDataValues.append(`dueDate-${data.number}`, data.dueDate)
        formDataValues.append(`currency-${data.number}`, data.issuedCurrency)
        formDataValues.append(`caseOwner-${data.number}`, data.caseOwner)
        formDataValues.append(`fiscalYear-${data.number}`, data.fiscalYear)
        formDataValues.append(
          `salesOrganization-${data.number}`,
          data.salesOrganization
        )
        formDataValues.append(
          `attachmentsUrl-${data.number}`,
          `${process.env.REACT_APP_INVOICES_UI}/app/dispute-information?documentNumber=${data.number}&customerCode=${data.customerCode}`
        )

        for await (let upload of data.uploadedFiles) {
          formDataValues.append(`${data.number}`, upload)
        }
      }

      formDataValues.append('userEmail', user.email)
      formDataValues.append('email', user.email)

      try {
        setIsLoading(!isLoading)
        await createDispute(formDataValues)
        setActiveStep(0)
        setIsReviewDialogOpen(!isReviewDialogOpen)
        clearValues()
        dispatch(applySelectFilter(invoicesFilters.selected))
        dispatch(fetchCompanies({ selects: invoicesFilters.selected }))
        dispatch(changeLoading(true))
        setIsLoading(!isLoading)

        return Swal.fire(
          t('success'),
          'Dispute cases created successfully.',
          'success'
        )
      } catch (error) {
        setActiveStep(0)
        setIsReviewDialogOpen(!isReviewDialogOpen)
        clearValues()
        dispatch(applySelectFilter(invoicesFilters.selected))
        dispatch(fetchCompanies({ selects: invoicesFilters.selected }))
        dispatch(changeLoading(true))

        return Swal.fire(
          t('error'),
          'Something went wrong when trying to create dispute.',
          'error'
        )
      }
    }

    const newPage = page + 1
    setPage(newPage)
  }

  const handleBack = () => {
    if (page === 1) {
      setActiveStep(0)
      setIsReviewDialogOpen(!isReviewDialogOpen)
      setDisputeRequestDialogOpen(true)
    }

    const newPage = page - 1
    setPage(newPage)
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Attention!',
      text: 'Are you sure to delete the invoice from Dispute Request?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        const newValues = values
        const index = newValues.findIndex((value) => value.id === id)
        newValues.splice(index, 1)

        const pages = newValues.slice(page * offset - offset, offset * page)

        if (pages.length === 0) setIsReviewDialogOpen(!isReviewDialogOpen)

        setDisputeCasesPaginated(pages)
      }
    })
  }

  useEffect(() => {
    let pages

    if (page > 1) {
      pages = values.slice(page * offset - offset, offset * page)
    } else {
      pages = values.slice(page - 1, offset * page)
    }

    setDisputeCasesPaginated(pages)
  }, [values, page])

  return isLoading ? (
    <Loading />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
          {t('customer')}
        </Typography>
        <Typography variant='h5'>
          {customerSelected[0].selected.label}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
        <Divider />
      </Grid>
      {disputeCasesPaginated.map((value) => (
        <Fragment key={value.id}>
          <Grid
            item
            xs={12}
            lg={12}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'end'
            }}>
            <IconButton size='10px' onClick={() => handleDelete(value.id)}>
              <Close style={{ color: 'red' }} />
            </IconButton>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('dispute-invoice-number')}
            </Typography>
            <Typography>{value.number}</Typography>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('currency')}
            </Typography>
            <Typography>{value.issuedCurrency}</Typography>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('dispute-amount')}
            </Typography>
            <Typography>{value.totalAmount}</Typography>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('dispute-reference')}
            </Typography>
            <Typography>{value.referenceNumber}</Typography>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('dispute-issued-date')}
            </Typography>
            <Typography>{value.issuedDate}</Typography>
          </Grid>

          <Grid item xs={12} lg={2}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('dispute-due-date')}
            </Typography>
            <Typography>{value.dueDate}</Typography>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('dispute-reason-label')}
            </Typography>
            <Typography>{value.description}</Typography>
          </Grid>
          <Grid item xs={12} lg={6} style={{ marginBottom: '10px' }}>
            {value.uploadedFiles.length > 0 ? (
              <Fragment>
                <Typography style={{ fontWeight: 'bold' }}>
                  {t('dispute-attachments')}
                </Typography>
                <FileList
                  files={value.uploadedFiles}
                  t={t}
                  overflow={true}
                  maxHeight={'100px'}
                  minHeight={'50px'}
                />
              </Fragment>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={12}>
            <Divider />
          </Grid>
        </Fragment>
      ))}
      <Grid
        item
        xs={12}
        lg={12}
        style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant='contained' color='primary' onClick={handleBack}>
          {t('back')}
        </Button>
        {`${page}/${totalPages}`}
        <Button variant='contained' color='primary' onClick={handleNext}>
          {page === totalPages ? t('submit') : t('next')}
        </Button>
      </Grid>
    </Grid>
  )
}
