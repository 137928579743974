import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import ReportProblem from '@material-ui/icons/ReportProblem'

import TableCell from '../../../component/table/table-cell'
import Loading from '../../../component/loading'
import Tooltip from '../../../component/tooltip'
import { setToastMessage } from '../../snackbar/__redux__/actions'
import {
  hasPdf,
  getPdf,
  findDocumentById,
  getNumberPdf
} from '../../../domain/document'

const renderIcon = (row) =>
  hasPdf(row) ? (
    <IconButton aria-label='pdf'>
      <PdfIcon fontSize='large' style={{ color: '#f66166' }} />
    </IconButton>
  ) : (
    <Tooltip
      textTooltip='PDF is not available'
      color='#ffffff'
      position='flex'
      backgroundColor='rgba(0,0,0,0.75)'>
      <div>
        <IconButton aria-label='pdf' disabled>
          <PdfIcon fontSize='large' title='PDF Not Generated' />
        </IconButton>
      </div>
    </Tooltip>
  )

const PdfCell = ({ row }) => {
  const [stateDownloadPdf, setStateDownloadPdf] = useState(false)
  const [stateError, setStateError] = useState(false)
  const dispatch = useDispatch()

  const forceDownload = async (doc) => {
    const blob = new Blob([new Uint8Array(doc.file.Body.data).buffer], {
      type: 'application/pdf'
    })

    const numberPdf = getNumberPdf({
      number: doc.number,
      assignmentNumber: doc.assignmentNumber,
      billingNumber: doc.billingNumber,
      type: doc.type
    })

    const namePdf = `${doc.customerCode}_${numberPdf}.pdf`

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', namePdf)
    document.body.appendChild(link)
    link.click()
  }

  const downloadPdf = async (row) => {
    setStateError(false)
    setStateDownloadPdf(true)

    try {
      const { id, type } = row

      if (['CC', 'CD', 'CV'].includes(type)) {
        const {
          data: { body }
        } = await findDocumentById(id)
        if (body && body.file) await forceDownload(body)
        else setStateError(true)
      } else {
        const {
          data: { body }
        } = await findDocumentById(id)
        if (body && body.file) {
          await forceDownload(body)
        } else {
          await getPdf(row)
        }
      }
    } catch (error) {
      setStateError(true)
      dispatch(
        setToastMessage({
          open: true,
          variant: 'error',
          message: 'An error occurred while getting PDF!'
        })
      )
    }
    setStateDownloadPdf(false)
  }

  return (
    <TableCell
      align='left'
      data-label-thead='Credit Note'
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (hasPdf(row)) downloadPdf(row)
      }}>
      {stateDownloadPdf ? <Loading downloadBig /> : renderIcon(row)}
      {stateError ? (
        <Tooltip
          textTooltip='Document not found!'
          color='#ffffff'
          position='bottom-end'
          backgroundColor='rgba(0,0,0,0.75)'>
          <ReportProblem fontSize='large' style={{ color: '#f66166' }} />
        </Tooltip>
      ) : null}
    </TableCell>
  )
}

export default PdfCell
