import React from 'react'
import Button from '../../../component/button'

export default (props) => {
  const handleGoToDisputeTab = () => {
    localStorage.setItem(
      '@caseNumber',
      JSON.stringify({
        label: props.caseNumber,
        value: props.caseNumber
      })
    )

    return (window.location.href = `${process.env.REACT_APP_INVOICES_UI}/app/dispute-information`)
  }

  return <Button onClick={handleGoToDisputeTab}>{props.caseNumber}</Button>
}
