import React, { Fragment } from 'react'
import TableBodyMUI from '../../../component/table/table-body'
import TableCell from '../../../component/table/table-cell'
import TableRow from '../../../component/table/table-row'
import { orderBy } from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import DisputeIcon from './dispute-icon'
import GetAttachmentsCell from './get-attachments-cell'
import PdfCell from './pdf-cell'
import getOverdueClass from './get-overdue-class'

const TableBody = (props) => {
  return (
    <TableBodyMUI>
      {orderBy(props.rows, props.orderBy, props.order).map((row, index) => {
        const labelId = `enhanced-table-checkbox-${index}`

        return (
          <Fragment>
            <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
              <TableCell data-label-thead='Customer' id={labelId}>
                {row.customerCode}
              </TableCell>
              <TableCell align='left' data-label-thead='Invoice'>
                {row.number}
              </TableCell>
              <TableCell align='left' data-label-thead='Dispute Case #'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  {row.caseNumber}
                  {<DisputeIcon />}
                </div>
              </TableCell>
              <TableCell align='left' data-label-thead='Dispute'>
                {row.status}
              </TableCell>
              <TableCell align='left' data-label-thead='Total'>
                {numeral(row.totalAmount).format('0,0.00')}
              </TableCell>
              {[
                'Disposition Provided - Full Credit Applied',
                'Disposition Provided - Partial Credit Applied'
              ].includes(row.dispositionReason) ? (
                <PdfCell row={row} />
              ) : (
                <TableCell align='left' data-label-thead='Credit Note'>
                  {null}
                </TableCell>
              )}
              <TableCell align='left' data-label-thead='Issued Date'>
                {moment(row.emissionDate).format('YYYY-MM-DD')}
              </TableCell>
              <TableCell
                align='left'
                data-label-thead='Due Date'
                className={getOverdueClass(row)}>
                {moment(row.dueDate).format('YYYY-MM-DD')}
              </TableCell>
              <TableCell align='left' data-label-thead='Disposition Reason'>
                {row.dispositionReason}
              </TableCell>
              <TableCell align='left' data-label-thead='Attachments'>
                <GetAttachmentsCell attachments={row.attachments} />
              </TableCell>
            </TableRow>
          </Fragment>
        )
      })}
    </TableBodyMUI>
  )
}

export default TableBody
